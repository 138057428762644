import React from "react"
import Logo from "../../images/logo.svg"
import { FooterLogo } from "../FooterLogo"
import "./footer.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare} from "@fortawesome/free-brands-svg-icons"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faPinterest } from "@fortawesome/free-brands-svg-icons"
import {gsap, Power3} from "gsap"
import {useRef, useEffect} from "react"
import { ScrollTrigger } from "gsap/ScrollTrigger";



export default function Footer () {
    // gsap.registerPlugin(ScrollTrigger)
    // let footer = useRef(null);
    // let footerIcons = useRef(null)

    // useEffect(() => {
    //     gsap.from(footer, {duration: 1, opacity: 0, ease:Power3.easeInOut, scrollTrigger: footer})
    //     gsap.from(footerIcons, {duration: 1, opacity: 0, ease:Power3.easeInOut, scrollTrigger: footerIcons})
    // },[])


    return (
        <footer className="footer">
            <FooterLogo fill="#25564b"/>
            <div className="footer__links" >
                <a className="footer__link" href="#">About</a>
                <a className="footer__link" href="#">Services</a>
                <a className="footer__link" href="#">Projects</a>
            </div>
            <div className="footer__socials" >
            <FontAwesomeIcon className="footer__icons" color="#25564b" icon={faFacebookSquare} />
            <FontAwesomeIcon className="footer__icons" color="#25564b" icon={faInstagram} />
            <FontAwesomeIcon className="footer__icons" color="#25564b" icon={faTwitter} />
            <FontAwesomeIcon className="footer__icons" color="#25564b" icon={faPinterest} />
            </div>
        </footer>
    )
}