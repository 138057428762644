import {React, useEffect, useRef} from "react"
import "./testimonials.scss"
import Emily from "../../images/image-emily.jpg"
import Thomas from "../../images/image-thomas.jpg"
import Jennie from "../../images/image-jennie.jpg"
import {gsap, Power3} from "gsap"

export default function Testimonials () {

    // let testIntro = useRef(null)

    // let testCard1 = useRef(null)
    // let testCard2 = useRef(null)
    // let testCard3 = useRef(null)
    
    // useEffect(() => {
    //     gsap.from(testIntro, {duration: 1, opacity: 0, y: -30, ease: Power3.easeOut, scrollTrigger: testIntro})
    //     gsap.from([testCard1, testCard2, testCard3], {duration: 1, opacity: 0, y: -30, ease:Power3.easeInOut, stagger: 0.3, scrollTrigger: testCard1})
    // },[])

    return (
        <section className="testimonials">
            <h3 className="testimonials__intro" >Client Testimonials</h3>
            <div className="grid-1x3">
                <div className="testimonial__card" >
                    <img className="testimonials__img" src={Emily} alt="Image of author"/>
                    <p className="testimonials__quotes">We put our trust in Sunnyside and they delivered, making sure our needs were met and deadlines were always hit.</p>
                    <h4 className="testimonials__author">Emily R.</h4>
                    <p className="testimonials__job">Marketing Director</p>
                </div>
                <div className="testimonial__card" >
                    <img className="testimonials__img" src={Thomas} alt="Image of author"/>
                    <p className="testimonials__quotes">Sunnyside’s enthusiasm coupled with their keen interest in our brand’s success made it a satisfying and enjoyable experience.</p>
                    <h4 className="testimonials__author">Thomas S.</h4>
                    <p className="testimonials__job">Chief Operating Officer</p>
                </div>
                <div className="testimonial__card" >
                    <img className="testimonials__img" src={Jennie} alt="Image of author"/>
                    <p className="testimonials__quotes">Incredible end result! Our sales increased over 400% when we worked with Sunnyside. Highly recommended!</p>
                    <h4 className="testimonials__author">Jennie F.</h4>
                    <p className="testimonials__job">Business Owner</p>
                </div>
            </div>
        </section>
    )
}