import React from "react"
import "./header.scss"
import Logo from "../../images/logo.svg"
import ArrowDown from "../../images/icon-arrow-down.svg"
import {useState, useEffect, useRef} from "react"
import {gsap, Power3, Bounce} from "gsap"


export default function Header () {
    const [active, setActive] = React.useState(false)

    // let headerNav = useRef(null)
    // let headerMain = useRef(null)
    // let headerArrow = useRef(null)

    // useEffect(() => {
    //     gsap.from(headerNav, {duration: 1, opacity: 0, ease: Power3.easeInOut})
    //     gsap.from(headerMain, {duration: 1, opacity: 0, y: -50, ease: Power3.easeOut, delay: 0.5})
    //     gsap.from(headerArrow, {duration: 2, opacity: 0, y: -10, ease: Bounce.easeOut, delay: 1})
    // },[])

    function toggleActive() {
        setActive(prevState => !prevState)
    }
    return (
        <header className="header">
            <nav className="header__nav" >
                <img src={Logo} alt="" />
                <div className="header__nav__hamburger" onClick={toggleActive}>
                    <div className="line"></div>
                    <div className="line middle-line"></div>
                    <div className="line"></div>
                </div>
                <div className="header__nav__desktop">
                    <a href="#" className="header__nav__desktop__link">About</a>
                    <a href="#" className="header__nav__desktop__link">Services</a>
                    <a href="#" className="header__nav__desktop__link">Project</a>
                    <a href="#" className="header__nav__desktop__button">Contact</a>
                </div>
            </nav>
            <section className="header__main">
                <h1 className="header__main__title" >We are creatives</h1>
                <img className="header__main__arrow" src={ArrowDown} alt="" />
            </section>
            <div 
            className={active ? "header__menu header__menu--active" : "header__menu"} 
            >
                <a href="#" className="header__menu__link">About</a>
                <a href="#" className="header__menu__link">Services</a>
                <a href="#" className="header__menu__link">Projects</a>
                <a href="#" className="header__menu__button">Contact</a>
            </div>
        </header>
    )
}