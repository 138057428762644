import logo from './logo.svg';
import "./app.scss"
import Header from "./components/Header/Header"
import Main from "./components/Main/Main"
import Testimonials from './components/Testimonials/Testimonials';
import Gallery from './components/Gallery/Gallery';
import Footer from './components/Footer/Footer';
import {gsap} from "gsap"
import {React, useRef, useEffect} from "react"

function App() {
  let app = useRef(null)
  useEffect(() => {
    gsap.to(app, {duration: 0, css: {visibility: "visible"}})
  })

  return (
    <div className="app" ref={el => app = el}>
      <Header />
      <Main />
      <Testimonials />
      <Gallery />
      <Footer />
    </div>
  );
}

export default App;
