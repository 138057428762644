import React from "react"
import "./gallery.scss"


export default function Gallery () {
    return (
        <section className="gallery">
           <div className="gallery__image image__milk"></div>
           <div className="gallery__image image__orange"></div>
           <div className="gallery__image image__cone"></div>
           <div className="gallery__image image__sugar"></div>
        </section>
    )
}