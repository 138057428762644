import {React, useEffect, useRef} from "react"
import "./main.scss"
import PinkCup from "../../images/mobile/image-stand-out.jpg"
import {gsap, Power3} from "gsap"


export default function Main() {
    // let mainTitle = useRef(null)
    // let mainDesc = useRef(null)
    // let mainLink = useRef(null)
    // let yellow = useRef(null)

    // let mainTitle2 = useRef(null)
    // let mainDesc2 = useRef(null)
    // let mainLink2 = useRef(null)
    // let pink = useRef(null)

    // let mainTitle3 = useRef(null)
    // let mainDesc3 = useRef(null)

    // let mainTitle4 =useRef(null)
    // let mainDesc4 =useRef(null)

    // useEffect(() => {
    //     gsap.from([mainTitle, mainDesc, mainLink, yellow], {duration: 1, opacity: 0, x: -30, stagger: .3, ease:Power3.easeInOut, scrollTrigger: mainTitle, delay: 0.3})
    //     gsap.from([mainTitle2, mainDesc2, mainLink2, pink], {duration: 1, opacity: 0, x: 30, stagger: .3, ease:Power3.easeInOut, scrollTrigger: mainTitle2, delay: 0.3})
    //     gsap.from([mainTitle3, mainDesc3], {duration: 1, opacity: 0, y: -30, stagger: .3, ease:Power3.easeOut, scrollTrigger: mainTitle3, delay: 0.3})
    //     gsap.from([mainTitle4, mainDesc4], {duration: 1, opacity: 0, y: 30, stagger: .3, ease:Power3.easeOut, scrollTrigger: mainTitle4, delay: 0.3})
    // },[])

    return (
        <main className="main grid grid__6x2">
            <div className="grid--2"></div>
            <div className="grid--1 main__text">
                <h2 className="main__title" >Transform your brand</h2>
                <p className="main__desc" >We are a full-service creative agency 
                specializing in helping brands grow fast. 
                 Engage your clients through compelling 
                 visuals that do most of the marketing for you.</p>
                 <a href="#" className="main__link" >Learn More</a>
                 <div className="main__link__highlight yellow" ></div>
            </div>
            <div className="grid--3"></div>
            <div className="grid--4 main__text">
                <h2 className="main__title" >Stand out to the right audience</h2>
                    <p className="main__desc" >Using a collaborative formula of designers, 
                    researchers, photographers, videographers, 
                    and copywriters, we’ll build and extend your brand in digital places. </p>
                    <a href="#" className="main__link" >Learn More</a>
                    <div className="main__link__highlight pink" ></div>
            </div>
            <div className="grid--5 main__text cherry-bg">
                <h2 className="main__title margin-up green-title" >Graphic Design</h2>
                    <p className="main__desc green-desc" >Great design makes you memorable. 
                    We deliver artwork that underscores your brand message 
                    and captures potential clients’ attention. </p>
            </div>
            <div className="grid--6 main__text orange-bg">
                <h2 className="main__title margin-up blue-title" >Photography</h2>
                    <p className="main__desc blue-desc" >Increase your credibility by getting the most stunning, 
                    high-quality photos that improve your business image.</p>
            </div>
        </main>
    )
}